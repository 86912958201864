import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "@/api/ajax"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.prototype.$axios = axios
Vue.use(ElementUI);

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
console.log(to,store.state.usreinf.id)

if(to.meta.isLogin){

  if(store.state.usreinf.id){
    next()
  }else{
    
    next('/')
  }

}else{
  next()
}

});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
