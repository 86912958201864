<template>
  <!-- <div :class="$route.path=='/'?'home_nav':'top_nav'" :style="$route.path=='/Access'?AccessStyle:''"> -->
  <!-- <div :class="$route.path=='/'||$route.path=='/Access'?'home_nav':'top_nav'"> -->
  <!-- <div :class="['/','/Access'].indexOf($route.path)>-1?'home_nav':'top_nav'"> -->
  <div :class="['/'].includes($route.path) ? 'home_nav' : 'top_nav'">
    <!-- <div :class="{
  home_nav:$route.path==('/'||'Access'),
  top_nav:$route.path!=='/'||'Access'
}"> -->
    <!-- 头部插件 -->
    <div class="thead_barrier">
      <img src="../assets/img/logo备份 10.png" class="app_logo" />
      <!-- <span :v-if="">123</span> -->
      <span class="name_box">
        <span :class="$route.path == '/' ? 'colorFont' : 'app_name'">触音</span>
      </span>
      <div class="details_box">
        <span
          v-for="(item, index) in navList"
          :key="item.navId"
          class="tab"
          @click="pitchNav(item, index)"
          :class="{
            tab_foc: $route.path == item.path,
            tab_color: $route.path == item.navId[0],
          }"
          >{{ item.navName }}</span
        >
      </div>
      <!-- 登录状态切换 -->
      <el-button
        v-if="!$store.state.usreinf.id"
        class="sign_in"
        @click="elastic"
        >登录/注册</el-button
      >
      <div v-if="$store.state.usreinf.id" class="after_login">
        <img :src="$store.state.usreinf.portraitAddress" alt="" width="40px" />
        <div class="user_name">{{ $store.state.usreinf.nickname }}</div>
        <!-- 鼠标移上去的效果 -->
        <div class="mouse_up">
          <div @click="myMusic">我的音乐</div>
          <div @click="SocietyManage" v-if="isshow.guildRole == 1">
            管理公会
          </div>
          <div @click="signOut">退出登录</div>
        </div>
      </div>

      <Popup ref="Popup" />
    </div>
  </div>
</template>

<script>
import Popup from "../components/LoginPopup.vue";

export default {
  components: {
    Popup,
  },
  data() {
    return {
      AccessStyle: {
        background: "rgba(0,0,0,.1)",
        color: "#fff",
      },
      x: true,
      showColor: false,
      showColor2: false,
      navList: [
        {
          navName: "首页",
          navId: 0,
          path: "/",
        },
        {
          navName: "动态",
          navId: 1,
          path: "/dynamic",
        },
        {
          navName: "约玩",
          navId: 2,
          path: "/AboutPlay",
        },
        {
          navName: "公会",
          navId: 4,
          path: "/Access",
        },
        {
          navName: "论坛",
          navId: 3,
          path: "https://cy.rzyou.com/",
        },
      ],
      isshow: {},
    };
  },
  watch:{
    isopen(value){
      if(value){
        this.elastic()
      }
    }
  },
  computed:{
    isopen(){
      return this.$store.state.showLoginBox
    }
  },
  created() {
    if (this.$store.state.usreinf.token) {
      this.getMyGuildInfo();
    }
  },
  methods: {
    SocietyManage() {
      this.$router.push("/MyGuild");
    },

    getMyGuildInfo() {
      this.$axios
        .post(
          "/guild/getMyGuildInfo",
          {},
          { headers: { token: this.$store.state.usreinf.token } }
        )
        .then((res) => {
          this.isshow = res.data.data || {};
          sessionStorage.setItem("gonghuixinxi", JSON.stringify(res.data.data));
        });
    },
    // 切换字体
    isShowColor() {
      if (this.$route.path == "/") {
        this.showColor = true;
        this.showColor2 = false;
      } else {
        this.showColor2 = true;
        this.showColor = false;
      }
    },
    pitchNav(item, index) {
      // 点击之后仓库内值改变为值
      console.log(item, index);
      if (item.navId === 4) {
        // console.log(item)
        this.$router.push("/Access");
      } else if (item.navId === 3) {
        window.open(item.path);
      } else {
        this.$router.push(item.path);
      }
    },
    // 登录改变按钮
    elastic() {
      this.$refs.Popup.open().then((res) => {
        // 保存用户信息
        this.$store.commit("usreinfs", res.user);
        this.$store.commit("showLoginBox", false);
        this.getMyGuildInfo();
        this.isshow = JSON.parse(
          sessionStorage.getItem("gonghuixinxi") || "{}"
        );
      });
    },
    // 退出登录
    signOut() {
      this.$store.commit("Logout");
      !$store.state.usreinf.id;
      this.isshow = {};
      console.log(this, isshow, "_____");
    },
    // 我的音乐
    myMusic() {
      this.$router.push("/Uploadmusic");
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.thead_barrier {
  height: 128px;
  width: 1000px;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  .app_logo {
    width: 85px;
    height: 85px;
  }
  .app_name {
    font-size: 40px;
    margin-left: 32px;
  }
  .details_box {
    display: flex;
    justify-content: space-between;
  }
  .tab {
    font-size: 18px;
    color: #888;
    margin-left: 70px;
    width: 50px;
    text-align: center;
    cursor: pointer;
  }
  .tab_foc {
    font-size: 18px;
    color: #eb4297;
    margin-left: 70px;
    font-weight: 600;
    cursor: pointer;
  }
  .after_login {
    position: absolute;
    right: 0;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin: 0px;
      padding: 0px;
    }
    .user_name {
      margin: 0;
      padding: 0;
      font-size: 8px;
      font-weight: 800;
      color: #888;
      text-align: center;
      line-height: 8px;
    }
  }
  .sign_in {
    width: 100px;
    height: 30px;
    line-height: 7px;
    font-size: 10px;
    color: #fff;
    background-color: #eb4297;
    border-radius: 15px 15px;
    border: none;
    position: absolute;
    right: 0;
  }
  .sign_in:active {
    box-shadow: 0 0 2px #eb4297;
    border: #ccc solid 1px;
  }
}

.tab_color {
  color: #fff;
}
.colorFont {
  font-size: 40px;
  margin-left: 32px;
  color: #fff;
}
.top_nav {
  width: 100%;
  background: #fff;
}
.home_nav {
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
}

.mouse_up {
  // padding: 5px;
  border-radius: 5px;
  min-width: 60px;
  background-color: #fff;
  color: #eb4297;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  overflow: hidden;
  border: #ccc 1px solid;
  display: none;
  div {
    line-height: 30px;
    font-size: 10px;
    color: #000;
    cursor: pointer;
  }
  div:hover {
    color: #eb4297;
    text-decoration: underline;
    background: #ccc;
  }
  // a{
  //   text-decoration: none;
  //   color: #000;
  //   font-size: 10px;
  // }
  // a:hover{
  //   color: #eb4297;
  //   text-decoration: underline;
  // }
}
.after_login {
  position: relative;
}
.after_login:hover > .mouse_up {
  display: block;
}
</style>