<template>
  <div class="water_box">
      <div>
    <el-table :data="tableData"  show-summary style="width:100%">
      <el-table-column prop="roomName" label="房间名" width="180"> </el-table-column>
      <el-table-column prop="totalAmont" label="流水"> </el-table-column>
      <el-table-column prop="autoRoomAmount" sortable label="已自动返点到房主">
      </el-table-column>
      <el-table-column prop="examinePresidentAmount" sortable label="需审核返点到会长">
      </el-table-column>
      <el-table-column prop="examineRoomAmount" sortable label="需审核返点到房主">
      </el-table-column>
    </el-table>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData:[]
    };
  },
  created() {
    //  this.myId = this.$route.params.id;
    //  console.log(this.myId )
    // console.log(Number(this.$route.query.id));
    this.$axios
      .post(
        "/guild/getGuildTurnoverDetailList",
        JSON.stringify({
          param: { turnoverId: this.$route.query.id },
        }),
        {
          headers: { token: this.$store.state.usreinf.token },
        }
      )
      .then((res) => {
        if(res.data.code==0){
        this.tableData = res.data.data

        }
       
      });
  },
};
</script>

<style lang="less" scoped>
.water_box{
    // margin:20px ;
    padding: 20px;
    overflow-y:scroll;
}
.water_box::-webkit-scrollbar {
    display: none;
  }
</style>