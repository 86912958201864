<template>
  <div>
    <div class="line"></div>
    <div class="box">
      <!-- 用户 -->
      <div class="user_upload">
        <div class="user">
          <div class="tox">
            <img :src="$store.state.usreinf.portraitAddress" alt="" />
          </div>
          <div class="user_name">
            <div class="name">{{ $store.state.usreinf.nickname }}</div>
            <div class="user_id">触音ID：{{ $store.state.usreinf.showId }}</div>
          </div>
        </div>
        <!-- 上传按钮 -->
        <div class="upload">
          <div class="uploaded">已上传歌曲:{{tableData.length}}首</div>
          <el-button type="primary" class="primary" @click="uploadmusic">上传歌曲</el-button>
        </div>
      </div>
      <!-- 已上传的音乐 -->
      <div class="music_uploaded">
        <el-table :data="tableData" style="width: 100%" max-height="500px">
          <el-table-column prop="author" label="歌曲名称" >
          </el-table-column>
          <el-table-column prop="name" label="演唱者">
          </el-table-column>
          <el-table-column label="歌曲" width="300px">
              <template slot-scope="scope">
            <div class="music-item"> 
            <audio controls >
              <source :src="scope.row.url" type="audio/mpeg">
            您的浏览器不支持 audio 元素。
            </audio>
          </div>  
          </template>
          </el-table-column>
          <el-table-column prop="createTime" label="上传时间" width="145px">
          </el-table-column>
          <el-table-column prop="-" label="文件大小">
              -
          </el-table-column>
          <el-table-column prop="information" label="其他信息">
              审核通过
          </el-table-column>
        </el-table>
      </div>

    </div>
    <div class="article">
        <div>©2019-2022 触音 . All rights reserved. 粤ICP备19079774号 | 深圳市欢时科技有限公司</div>
        <div>邮箱：9911107@qq.com丨微信：chuyu52099</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        
      tableData: [],
    };
  },
  methods: {
    getData() {
      this.$axios
        .post(
          "/music/getMusicUploadList",JSON.stringify({params: {}}),
          { headers: { token: this.$store.state.usreinf.token } }
        )
        .then((res) => {
            
            this.tableData = res.data.data
            // console.log(this.tableData)
        });
    },
    uploadmusic(){
        this.$router.push('/fillindata')
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="less" scoped>
.line {
  margin-top: 128px;
  width: 100%;
  border-top: 1px #ccc solid;
}
.box {
  width: 100%;
  min-width: 1000px;
  .user_upload {
    margin: auto;
    margin-top: 25px;
    width: 1000px;
    height: 180px;
    box-sizing: border-box;
    border: solid 1px #999;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .user {
      display: flex;
      .tox {
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }
      }
      .user_name {
        margin-left: 15px;
        margin-top: 25px;
        .name {
          font-size: 25px;
          margin: 0px;
          padding: 0px;
        }
        .user_id {
          font-size: 7px;
          margin: 0px;
          padding: 0px;
        }
      }
    }
    .upload {
      height: 100px;
      text-align: center;
      margin-top: 15px;
      .primary {
        width: 160px;
        height: 45px;
        font-size: 8px;
        font-weight: 400;
        background-color: #590db8;
        border: 1px solid #ccc;
      }
      .primary:hover {
        background-color: #6b26bf;
      }
      .uploaded {
        line-height: 30px;
      }
    }
  }
  .music_uploaded {
    margin: auto;
    margin-top: 25px;
    width: 1000px;
    height: 500px;
    max-height: 600px;
    overflow: hidden;
    box-sizing: border-box;
    border-top: #ccc 1px solid;
    border-bottom: solid #ccc 1px;
  }
}
.music-item{
      height: 60px;
      display: flex;
      align-items: center;
    }
    .article{
        width: 1000px;
        margin: auto;
        text-align: center;
        margin-top: 60px;
        div{
            font-size: 18px;
            color: #ccc;
        }
    }
</style>