<template>
  <div class="aaa">
    <div class="baba"></div>
    <div class="comment_box" v-for="item in commentList" :key="item.id">
      <div class="comment_list">
        <!-- 头像 -->
        <div class="portrait">
          <img :src="item.portraitAddress" alt="" />
        </div>
        <!-- 评论 -->
        <div class="cet_information">
          <!-- 评论信息 -->
          <div class="information">
            <span>{{ item.nickname }}</span
            ><img :src="item.sex == 1 ? img1 : img2" alt="" class="sex" />
            <p>{{ item.createTime }}</p>
            <div class="dynamic">{{ item.dynamic }}</div>
          </div>
          <!-- 图片/视频 -->
          <div class="picture_video">
            <video
              v-if="item.resourceType == 2 && item.resource1"
              :src="item.resource1"
              controls="controls"
              width="200px"
            ></video>
            <div v-if="item.resourceType == 1 && item.resource1">
              <img :src="item.resource1" alt="" class="comment_picture" />
            </div>
          </div>
        </div>
        <!-- 有没有人评论他 -->
        <div class="comment_like">
          <span>{{ item.commentCounts }}</span>
          <img src="../assets/img/评论.png" alt="" />
          <span>{{ item.likeCounts }}</span>
          <img src="../assets/img/点赞.png" alt="" />
        </div>
        <!-- /点赞 -->
        <div class="little_good" v-if="item.likeList.length > 0">
          <img src="../assets/img/点赞.png" alt="" width="30px" />
          <div class="have" v-for="item in item.likeList" :key="item.id">
            <img :src="item.portraitAddress" alt="" width="60px" />
          </div>
        </div>
        <!-- /评论 -->
        <div class="have_theory" v-if="item.commentList.length > 0">
          <img src="../assets/img/评论.png" alt="" class="comment_img" />
          <div class="avatar_list_box">
            <div
              v-for="item in item.commentList"
              :key="item.id"
              class="avatar_list"
            >
              <div class="comment_avatar">
                <img :src="item.portraitAddress" alt="" width="60px" />
              </div>
              <div class="main">
                <div class="user_name">
                  <span class="name">{{ item.nickname }}</span>
                  <img :src="item.sex == 1 ? img1 : img2" alt="" />
                  <span class="times">{{ item.createTime }}</span>
                </div>
                <div>{{item.comment}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headNav from "../components/navigation.vue";
export default {
  components: {
    headNav,
  },
  data() {
    return {
        hasMore:true,
      img1: require("../assets/img/ic_sex_man1.png"),
      img2: require("../assets/img/女性备份.png"),
      json: {
        param: {
          pageSize: 10,//评论的条数
          startPage: 1,//页数
          lastId: 0,//初始id
        },
      },
      commentList: [],
    };
  },

  methods: {
    //   获取评论信息
    comment() {
      this.$axios
        .post(
          "/login/getDynamicList",
          JSON.stringify(this.json)
        )
        .then((res) => {
          this.commentList = this.commentList.concat(res.data.data.dynamicList)
          this.json.param.lastId = res.data.data.lastId
          this.isloading = false
          if(res.data.data.dynamicList.length<10){
              this.hasMore = false
          }else{
              this.hasMore = true
          }
        });
    },
    
    LoadMore() {
        window.addEventListener("scroll", (e)=>{
            let a = document.documentElement.scrollTop||document.body.scrollTop
            let b = document.documentElement.clientHeight
            let c = document.documentElement.offsetHeight      
            if(c-b<=a+1000&&!this.isloading&&this.hasMore){
                this.json.param.startPage += 1
                this.comment()
                this.isloading = true
            }


        }, true); 
    },
  },
  mounted() {
    this.LoadMore();
  },
  created() {
    this.comment();
  },
};
</script>

<style lang="less" scoped>
body {
  overflow-y: scroll;
}
.baba {
  height: 128px;
}
.comment_box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 1440px;
  border-bottom: #ccc 1px solid;
  padding: 40px 0 40px 0;
  .comment_list {
    width: 800px;
    .portrait {
      display: inline-block;
      float: left;
      margin-left: 20px;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
    }
    .cet_information {
      display: inline-block;
      margin-left: 20px;
      margin-bottom: 17px;
      width: calc(800px - 140px);
      .information {
        span {
          font-size: 25px;
        }
        .sex {
          width: 25px;
          height: 25px;
          margin-left: 10px;
        }
        p {
          margin: 0px;
          padding: 0px;
          color: #999;
          font-size: 20px;
        }
        .dynamic {
          font-size: 18px;
        }
      }
      .picture_video {
        div {
          width: 200px;
          height: 200px;
          overflow: hidden;
          position: relative;
          margin-top: 20px;
          .comment_picture {
            width: 200px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    .comment_like {
      width: 800px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
        margin-left: 20px;
        margin-right: 10px;
      }
      span {
        font-size: 20px;
        color: #999;
      }
    }
    .little_good {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .have {
        margin-left: 10px;
        img {
          border-radius: 50%;
        }
      }
    }
    .have_theory {
      display: flex;
      align-items: center;
      position: relative;
      .comment_img {
        position: absolute;
        top: 15px;
        left: 0px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      .avatar_list_box {
        margin-left: 40px;
        .avatar_list {
          display: flex;
          .comment_avatar {
            img {
              border-radius: 50%;
              margin-right: 20px;
            }
          }
          .main {
            .user_name {
              position: relative;
              width: 700px;
              img {
                width: 20px;
                height: 20px;
              }
              .times {
                  position: absolute;
                  top: 0px;
                  right: 30px;
                font-size: 18px;
                color: #999;
              }
              .name {
                font-size: 20px;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>