<template>
  <div class="app_box">
    <div class="app_nav">
      <headNav ref="homePage" />
    </div>
    <router-view />
  </div>
</template>
<script>
import headNav from "./components/navigation.vue";

export default {
  components: {
    headNav,
  },
  methods: {
    first() {
      this.$refs.homePage.kind();
    },
  },
};
</script>
<style lang="less">
* {
  padding: 0;
  margin: 0;
}

.app_nav {
  width: 100%;
  min-width: 1000px;
  position: fixed;
  top: 0;
  left: 0;
  // transform: translateX(-50%);
  z-index: 999;
}
.app_box {
  position: relative;
  min-width: 1000px;
}
</style>
