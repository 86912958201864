<template>
    <div class="content">
        <el-form :inline="true" :model="params" class="demo-form-inline">
            <el-form-item label="送礼人ID">
                <el-input v-model.number="params.userShowId"></el-input>
            </el-form-item>
            <el-form-item label="收礼人ID">
                <el-input v-model.number="params.toUserShowId"></el-input>
            </el-form-item>
            <el-form-item label="房间">
                <el-select v-model="params.roomId">
                    <el-option v-for="item in roomist" :label="item.name" :key="item.id" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="开始时间">
                <el-date-picker v-model="params.startTime" type="date" placeholder="选择日期时间"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间">
                <el-date-picker v-model="params.endTime" type="date" placeholder="选择日期时间"></el-date-picker>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="search">查询</el-button>
            </el-form-item>
        </el-form>
        <div style="line-height:40px">总流水：{{totalAmount}}</div>
        <el-table :data="tableData" style="width: 100%">
            <el-table-column label="房间名称" prop="roomName" width="180"></el-table-column>
            <el-table-column label="房间ID" prop="roomShowId"></el-table-column>
            <el-table-column label="厅主" width="180">
                <template slot-scope="scope">
                    <div class="userInfo">
                        <img
                            :src="scope.row.roomUserportraitAddress"
                            style="height:50px;width:50px"
                            alt
                        />
                        <div>
                            <div>{{ scope.row.roomUserNickname }}</div>
                            <!-- <div>ID:{{scope.row.roomUserNickname}}</div> -->
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="送礼人" width="180">
                <template slot-scope="scope">
                    <div class="userInfo">
                        <img
                            :src="scope.row.userportraitAddress"
                            style="height:50px;width:50px"
                            alt
                        />
                        <div>
                            <div>{{ scope.row.userNickName }}</div>
                            <div>ID:{{ scope.row.showId }}</div>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="收礼人" width="180">
                <template slot-scope="scope">
                    <div class="userInfo">
                        <img
                            :src="scope.row.toUserportraitAddress"
                            style="height:50px;width:50px"
                            alt
                        />
                        <div>
                            <div>{{ scope.row.toUserNickName }}</div>
                            <div>ID:{{ scope.row.toUserShowId }}</div>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="礼物名称" prop="giftName"></el-table-column>
            <el-table-column label="礼物数量" prop="num"></el-table-column>
            <el-table-column label="礼物总价值" prop="amount"></el-table-column>
            <el-table-column label="礼物来源">
                <template slot-scope="scope">
                    <span
                        style="margin-left: 10px"
                    >{{ scope.row.giveChannelId == 1 ? "房间" : scope.row.giveChannelId == 1 ? "私聊对话" : '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="送礼时间" prop="createTime"></el-table-column>
        </el-table>
        <div class="pagination">
            <el-pagination
                background
                :page-size="params.pageSize"
                @current-change="pageChange"
                layout="prev, pager, next"
                :total="total"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    data() {
        return {
            tableData: [],
            params: {
                startPage: 1,
                pageSize: 10,
            },
            total: 0,
            roomist:[],
            totalAmount:'-'
        }
    },
    created() {
        this.getRoomist()
        this.search()
    },
    methods: {
        getRoomist() {
            this.$axios.post('/guild/getGuildRoomListForWeb', JSON.stringify({
                param: {}
            }), {
                headers: { token: this.$store.state.usreinf.token },
            }).then(res => {
                this.roomist = res.data.data||[]
            })
        },
        search() {
            this.$axios.post('/guild/getGiftTurnoverForWeb', JSON.stringify({
                param: {
                    ...this.params,
                    startPage: this.params.startPage,
                    pageSize: this.params.pageSize,
                    startDate:this.params.startTime?moment(this.params.startTime).format('YYYY-MM-DD'):null,
                    endDate:this.params.endTime?moment(this.params.endTime).format('YYYY-MM-DD'):null
                }
            }), {
                headers: { token: this.$store.state.usreinf.token },
            }).then(res => {
                console.log(res)
                this.total = res.data.data.pageObj.total
                console.log(this.total)
                this.totalAmount=res.data.data.totalAmount
                this.tableData = res.data.data.pageObj.list || []
            })
        },
        handleDelete(index, row) {
            console.log(index, row);
        },
        pageChange(a) {
            this.params.startPage = a
            this.search()
        }
    }
}
</script>
<style lang="less" scoped>
.content {
    padding: 20px;
}
.pagination {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.userInfo {
    display: flex;
    align-items: center;
    div {
        padding-left: 5px;
    }
}
</style>
