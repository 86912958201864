<template>
  <div class="txt_box" v-show="Isshow">
    <div class="txt">
      <div>
        <span class="spanTop">公会须知</span>
      </div>

      <div class="span_box">
        <span class="spanA">1.什么是公会？</span>
        <span class="liTop">
          公会是指触音语音授权自然人、法人及其他组织在触音语音平台内管理和培养艺人的一种运营方式。
        </span>
      </div>
      <div class="span_box">
        <span class="spanA">2.成立公会将获得什么福利？</span>
        <span class="liTop"> · 获取签约艺人分成收益。 </span>
        <span class="liTop"
          >· 获取平台返点、返现、曝光资源等各类形式扶持。</span
        >
        <span class="liTop">· 平台举办的公会活动参与资格。</span>
      </div>
      <div class="span_box">
        <span class="spanA">3.如何创建公会？ </span>
        <span class="liTop"
          >绑定手机号→实名认证→登录触音官网（www.rzyou.com）→公会中心→填写创建信息→缴纳保证金→平台审核→成功创</span
        >
        <span class="liTop">建/驳回退保证金</span>
        <span class="liTop"
          >· 创建时向需要申请公会的OW号充值 <span class="colorSpan">100000</span> 钻作为保证金</span
        >
        <span class="liTop"
          >· 个人和公司均可创建公会，OW号实名需为会长本人。</span
        >
        <span class="liTop">· 如对创建及审查有疑问，请联系微信：。</span>
      </div>
      <div class="span_box">
        <span class="spanA">4.如何签约艺人？</span>
        <span class="liTop">· 公会邀请签约 </span>
        <span class="liTop">
          登录触音官网（www.rzyou.com）→公会中心→艺人管理→输入艺人id/昵称→邀请签约→艺人确认→签约成功。
        </span>
        <span class="liTop"> · 艺人申请签约 </span>
        <span class="liTop">
          登录触音语音→我的→我的公会→搜索公会→进入公会主页→申请签约→公会确认→签约成功。
        </span>
      </div>
      <div class="span_box">
        <span class="spanA">5.公会职责</span>
        <span class="liTop">
          ·
          有序管理和经营，避免出现拖欠厅薪资、内容违规、平台内恶意挖人等严重问题。
        </span>
        <span class="liTop">
          · 积极配合平台，参与平台举办的各类公会活动。
        </span>
        <span class="liTop">
          · 遵守平台公会独家合作协议，接受平台管理与监督。
        </span>
        <span class="liTop"> · 遵守触音语音平台各项管理规定。 </span>
        <span class="liTop">
          · 公会运营过程中不得有侵犯他人合法权利或其他违法违规行为。
        </span>
        <span class="liTop">
          · 对于违反以上行为以及不合格公会，平台有权回收公会资格与权限。
        </span>
      </div>
      <div class="but-box">
          <el-button type="info" @click="off">取消</el-button>
          <el-button type="primary" @click="affirm">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Isshow: false,
    };
  },
  methods: {
    open() {
      this.Isshow = true;
    },
    off(){
      this.Isshow = false;

    },
    affirm(){
      this.Isshow = false;
      this.$router.push("/SocietyDatum")
     
    }
  },
};
</script>

<style lang="less" scoped>

p {
  list-style: none;
  line-height: 0;
}
li {
  margin: 0;
  padding: 0;
}
span {
  display: block;
}
.txt_box {
  min-height: 100%;
  max-height:100%;
  //  min-height: 800px;
  min-width: 1050px;
  z-index: 10;
  position:absolute;
  top: 0;
  width: 100%;
  
  background: rgba(0, 0, 0, 0.2);
  
}

.txt {
    min-height:200px;
     padding: 50px 0;
     height: 400px;
    
  width: 810px;
  overflow-y: scroll;
  background: #fff;
  margin:  auto;
  margin-top:50px;

  // padding: 50px 0;
  .spanTop {
    text-align: center;
    height: 30px;
    font-size: 22px;
    font-weight: 500;
    color: #1b1c32;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  }
}
.txt::-webkit-scrollbar {
      display: none;
    }
.spanA {
  font-weight: 500;
  margin-left: 20px;
  color: #1b1c33;
  font-size: 18px;
  line-height: 40px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
}
.liTop {
  color: #666666;
  margin-left: 40px;
  font-size: 14px;
  line-height: 28px;

  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
}
.span_box {
  margin: 20px 0;
}
.but-box{
  margin-top:50px ;
width: 300px;
margin:auto;
display: flex;
justify-content: space-between;
}
.colorSpan{
  color: red;
  display: inline;
}
</style>