import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router';
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })],
  state: {
    switch:0,
    usreinf:{},
    showLoginBox:false
  },
  mutations: {
    usreinfs(state,res){
      state.usreinf = res
      state.showLoginBox = false
    },
    showLoginBox(state,res){
      state.showLoginBox = res
    },
    Logout(state){
      state.usreinf = {}
      window.location.reload()
    }
  },
  actions: {
  },
  modules: {
  }
})
