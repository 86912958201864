
import axios from "axios"
import store from '../store/index'
axios.interceptors.request.use(
  async (config) => {
    if(location.hostname=='localhost'||location.hostname=='test.rzyou.com'){
      config.url = "/api"+config.url
    }else{
      config.url = "https://api.rzyou.com" + config.url
    }
    console.log(config.url)
    const token = store.state.usreinf.token;
    token && (config.headers.token = token);
    return config;
  },
  error => {
    return Promise.error(error);
  }
);
axios.interceptors.response.use((res) => {
  // if (res.data.code !== "0") {
  //   Toast(res.data.msg)
  //   if (res.data.code === "10041") {
  //     app_closeAppPage && app_closeAppPage()
  //   } else {
  //     // router.back()
  //   }
  // }
  return res
}, (error) => {
  return error
})

export default axios