<template>
  <div v-show="isopen">
    <div class="disgraceful"></div>
    <div class="spring">
      <div class="logoimg">
        <img src="../assets/img/logo备份 10.png" />
      </div>
      <div class="spring_tehad">
        <h4>登录/注册</h4>
        <p>未注册的手机号验证后将自动创建账户</p>
      </div>
      <div class="phone_number">
        <input
          type="text"
          placeholder="请输入手机号码"
          v-model="verification.phone"
        />
      </div>
      <div class="verification_code">
        <input
          type="text"
          placeholder="请输入验证码"
          v-model="verification.code"
        />
        <div class="obtain" @click="getCode" v-show="x">获取验证码</div>
        <div class="obtain" v-show="!x">{{ countDown }}后重新获取</div>
      </div>
      <div class="button_box"><el-button @click="land">登录</el-button></div>
      <div class="button_box"><el-button @click="close">取消</el-button></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isshow: false,
      x: true,
      verification: {
        phone: "",
        code: "",
      },
      countDown: 30,
    };
  },
  computed:{
    isopen(){
      return this.isshow
    }
  },
  methods: {
    open() {
      return new Promise((resolve, reject) => {
        this.isshow = true;
        this.success = resolve;
        this.cancel = reject;
      });
    },
    // 点击登录
    land() {
      this.isshow = false;
      var lo = {
        token: "",
        version: "1.0",
        param: {
          phoneNumber: this.verification.phone,
          phoneImei: "",
          code: this.verification.code,
          channel: 1,
          platform: 1,
          loginType: "PC",
          token: "",
        },
      };
    // 登录请求
      this.$axios
        .post("/login/doLogin", JSON.stringify(lo))
        .then((res) => {
          this.success(res.data.data);
        });
    },
    
    validPhone(){
      
      this.$axios
        .post("/login/doLogin", JSON.stringify(lo))
        .then((res) => {
          this.success(res.data.data);
        });
    },
    // 点击取消
    close() {
      this.isshow = false;
      this.$store.commit("showLoginBox", false);
      this.cancel = this.cancel();
    },
    // 获取验证码
    getCode() {
      var lo = {
        token: "",
        version: "1.0",
        param: {
          phoneNumber: this.verification.phone,
          resend: "1",
          smsType: 1,
        },
      };
      if (/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.verification.phone)) {
        this.$axios
          .post("/login/sendSms", JSON.stringify(lo))
          .then((res) => {
            this.x = false;
            var a = setInterval(() => {
              this.countDown--;
              if (this.countDown == 0) {
                clearInterval(a);
                this.x = true;
                setTimeout(() => {
                  this.countDown = 30;
                }, 500);
              }
            }, 1000);
          });
      } else {
        this.$message("手机号码有有误！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.disgraceful {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.spring {
  width: 400px;
  height: 400px;
  background-color: #fff;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  //   padding: 40px;
  .spring_tehad {
    text-align: center;
    margin-top: 25px;
    h4 {
      margin: 0;
      padding: 0;
      color: #eb4297;
    }
    p {
      font-size: 1px;
      color: #777;
      margin-top: 10px;
    }
  }
  .phone_number {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    input {
      width: 70%;
      height: 30px;
      color: #333;
      border: none;
      outline: none;
      background-color: rgba(255, 255, 255, 0);
      border-bottom: #ccc 1px solid;
    }
  }
  .verification_code {
    width: 100%;
    display: flex;
    justify-content: center;

    position: relative;
    input {
      width: 70%;
      height: 30px;
      color: #333;
      border: none;
      outline: none;
      background-color: rgba(255, 255, 255, 0);
      border-bottom: #ccc 1px solid;
    }
    .obtain {
      position: absolute;
      right: 58px;
      top: 2.5px;
      font-size: 5px;
      background: #eee;
      width: 80px;
      color: #eb4297;
      line-height: 25px;
      text-align: center;
      cursor: pointer;
    }
  }
  .logoimg {
    margin: 0;
    padding: 0;
    background: #ccc;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #fff;
    height: 100px;
    background-image: linear-gradient(140deg, #eee, #eb4297, #eb4297);
    img {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      border: #fff 1px solid;
    }
  }
  .button_box {
    margin: 15px 0 0 100px;
    button {
      width: 200px;
      height: 30px;
      line-height: 7px;
      // font-size: 16px;
      color: #fff;
      background-color: #eb4297;
      border-radius: 15px 15px;
      border: none;
    }
  }
}
</style>