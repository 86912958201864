<template>
  <div class="data_box">
    <div class="contern">
      <span class="top_fontSize">创建时间：{{ param.createTime }}</span>
      <div class="contern-show">
        <el-form
          label-width="160px"
          label-position="left"
          :model="param"
          ref="List"
          :rules="rules"
          disabled
        >
          <div class="show_box">
            <div class="show-left">
              <el-form-item label="公会ID" prop="userId">
                <el-input
                  v-model="param.showId"
                  :disabled="true"
                  placeholder="请输入公会ID"
                ></el-input>
              </el-form-item>
              <el-form-item label="公会名称" prop="guildName">
                <el-input
                  v-model="param.guildName"
                  placeholder="请输入公会名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="公会简介" prop="signText">
                <el-input
                  v-model="param.signText"
                  style="width: 380px"
                  type="textarea"
                  maxlength="100"
                  :rows="1"
                  placeholder="请填写公会介绍，不超过100个字符"
                >
                </el-input>
              </el-form-item>
              <el-form-item label="公会LOGO" prop="logoUrl">
                <div class="boxImg">
                  <img :src="param.logoUrl" class="logoImg" />
                  <!-- <input
                    type="file"
                    class="addImg"
                    @change="
                      (e) => {
                        changFile(e, 'logoUrl');
                      }
                    "
                  /> -->
                </div>
              </el-form-item>
              <el-form-item label="会长姓名" prop="createUserName">
                <el-input
                  v-model="param.createUserName"
                  placeholder="请输入会长名字需和身份证一致"
                ></el-input>
              </el-form-item>
              <el-form-item label="会长身份证" prop="identityCard">
                <el-input
                  v-model="param.identityCard"
                  placeholder="身份证号需和身份证一致"
                ></el-input>
              </el-form-item>
              <el-form-item label="公会主体类型">
                <el-radio v-model="param.guildType" :label="1" disabled
                  >个人</el-radio
                >
                <el-radio v-model="param.guildType" :label="2" disabled
                  >企业</el-radio
                >
              </el-form-item>
              <div v-if="param.guildType == 2">
                <el-form-item label="企业名称" prop="companyName">
                  <el-input
                    v-model="param.companyName"
                    placeholder="请输入企业名称"
                  ></el-input>
                </el-form-item>
                <el-form-item label="营业执照" prop="businessNumber">
                  <el-input
                    v-model="param.businessNumber"
                    placeholder="请输入营业执照号，需和图片一致"
                  ></el-input>
                </el-form-item>
              </div>
              <span style="font-size: 28px; font-width: 700">联系方式</span>
              <el-form-item label="联系人姓名" prop="contactName">
                <el-input
                  v-model="param.contactName"
                  placeholder="请输入联系人姓名"
                ></el-input>
              </el-form-item>
              <el-form-item label="已验证联系电话" prop="contactPhone">
                <el-input
                  v-model.number="param.contactPhone"
                  maxlength="11"
                  placeholder="请输入手机号码"
                ></el-input>
              </el-form-item>
              <el-form-item label="QQ号" prop="qq">
                <el-input
                  v-model.number="param.qq"
                  placeholder="请输入QQ号"
                ></el-input>
              </el-form-item>
              <el-form-item label="微信号" prop="wx">
                <el-input
                  v-model="param.wx"
                  placeholder="请输入微信号"
                ></el-input>
              </el-form-item>
            </div>
            <div class="show-right">
              <el-form-item
                label="申请人身份证正面"
                prop="identityCardFronturl"
              >
                <div class="proposerImg_box">
                  <img :src="param.identityCardFronturl" class="proposerImg" />
                  <!-- <input
                    type="file"
                    class="file_box"
                    @change="
                      (e) => {
                        changFile(e, 'identityCardFronturl');
                      }
                    "
                  /> -->
                </div>
              </el-form-item>
              <el-form-item label="申请人身份证反面" prop="identityCardBackurl">
                <div class="proposerImg_box">
                  <img :src="param.identityCardBackurl" class="proposerImg" />
                  <!-- <input
                    type="file"
                    class="file_box"
                    @change="
                      (e) => {
                        changFile(e, 'identityCardBackurl');
                      }
                    "
                  /> -->
                </div>
              </el-form-item>
              <el-form-item
                v-if="param.guildType == 2"
                label="营业执照图片"
                prop="businessUrl"
              >
                <div class="proposerImg_box">
                  <img :src="param.businessUrl" class="proposerImg" />
                  <!-- <input
                    type="file"
                    class="file_box"
                    @change="
                      (e) => {
                        changFile(e, 'businessUrl');
                      }
                    "
                  /> -->
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <!-- <div class="add_button">
        <el-button @click="presentData" type="primary">保存</el-button>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      param: {},
      rules: {
        userId: [{ required: true, message: "请输入公会ID", trigger: "blur" }],
        guildName: [
          { required: true, message: "请输入公会名称", trigger: "blur" },
        ],
        signText: [
          { required: true, message: "请输入公会简介", trigger: "blur" },
          {
            min: 5,
            max: 100,
            message: "长度在 3 到  100个字符",
            trigger: "blur",
          },
        ],
        logoUrl: [
          { required: true, message: "请上传LOGO图片", trigger: "change" },
        ],
        createUserName: [
          {
            required: true,
            message: "请输入会长名字需和身份证一致",
            trigger: "blur",
          },
        ],
        identityCard: [
          {
            required: true,
            message: "请输入会长身份证号需和身份证一致",
            trigger: "blur",
          },
          { min: 18, max: 20, message: "身份证长度为18字符", trigger: "blur" },
        ],
        companyName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        businessNumber: [
          { required: true, message: "请输入营业执照", trigger: "blur" },
        ],
        contactName: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "请输入有效11位手机号",
            trigger: "blur",
          },
        ],
        qq: [{ required: true, message: "请输入QQ号", trigger: "blur" }],
        wx: [{ required: true, message: "请输入微信号", trigger: "blur" }],
        identityCardFronturl: [
          { required: true, message: "请上传身份证正面", trigger: "change" },
        ],
        identityCardBackurl: [
          { required: true, message: "请上传身份证反面", trigger: "change" },
        ],
        businessUrl: [
          { required: true, message: "请上传营业执照", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    changFile(e, key) {

   if(e.target.files[0].size/1024/1024>10){
 this.$message.error('图片大小只支持10M以内');
   }else{
      // 调用方法需要传该元素files的下标第一个给他，然后返回一个url
      this.updataFile(e.target.files[0], (url) => {
        // 返回的url赋值给param对象里相应的key名字
        this.param[key] = url;

        // 异步单个赋值方法
        // setTimeout(()=>{
        // this.ruleForm.proposerJustImg = url
        // },2000)
      });
   }


    },
    // 点击保存
    presentData() {
      this.$confirm("是否提交并保存修改？", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "提交并保存",
        cancelButtonText: "放弃修改",
      })
        .then(() => {
          this.$refs.List.validate()
            .then((res) => {
              let params = {
                param: this.param,
              };

              this.$axios
                .post(
                  "/guild/updateGuild",
                  JSON.stringify(params),
                  {
                    headers: { token: this.$store.state.usreinf.token },
                  }
                )
                .then((data) => {
                  
                  if (data.data.code == 0) {
                    this.$message({
                      message: data.data.msg,
                      type: "success",
                    });
                    this.$router.push('/MyGuild')


                  }else{
                  this.$message.error(data.data.msg);

                  }
               
                })
            })
            .catch(() => {
                this.$message.error("请核对修改内容正确");
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "放弃修改",
          });
        });
    },
    // 图片加密
    updataFile(file, cb) {
      var getAuthorization = function (options, callback) {
        // 格式四、（不推荐，适用于前端调试，避免泄露密钥）前端使用固定密钥计算签名
        var authorization = COS.getAuthorization({
          SecretId: "AKID5Zom24pOefwypStiSu6l9G7i25qKETjm", // 可传固定密钥或者临时密钥
          SecretKey: "x71qQHsFNFUS9cpcAbnjRyCF8qfLrDNQ", // 可传固定密钥或者临时密钥
          Method: options.Method,
          Pathname: options.Pathname,
          Query: options.Query,
          Headers: options.Headers,
          Expires: 900,
        });

        callback({
          Authorization: authorization,
          // SecurityToken: credentials.sessionToken, // 如果使用临时密钥，需要传 SecurityToken
        });
      };
      var cos = new COS({
        getAuthorization: getAuthorization,
        UploadCheckContentMd5: true,
      });
      let key = `music/${
        this.$store.state.usreinf.id
      }/${new Date().valueOf()}.png`; //.mp3
      cos.putObject(
        {
          Bucket: "music-1300805214" /* 填入您自己的存储桶，必须字段 */,
          Region: "ap-shanghai" /* 存储桶所在地域，例如ap-beijing，必须字段 */,
          Key: key /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
          Body: file, // 上传文件对象
          onProgress: function (progressData) {
            if (progressData.percent == 1) {
              cb("https://music-1300805214.file.myqcloud.com/" + key);
            }
          },
        },
        function (err, data) {
          if (err) {
            alert("上传文件出错请稍后再试");
          }
        }
      );
    },
    // 获取公会信息
    societyData() {
      this.$axios
        .post(
          "/guild/getGuildInfoForWeb",
          {},
          {
            headers: { token: this.$store.state.usreinf.token },
          }
        )
        .then((res) => {
          
          if (res.data.code == 0) {
            this.param = res.data.data;
          }
        });
    },
  },
  created() {
    this.societyData();
  },
};
</script>

<style lang="less" scoped>
.data_box {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  min-width: 1200px;
  height: calc(100%);
  background: #fff;
  .contern {
    width: 100%;
    background: #fff;
    // height: 600px;
    max-height: 78vh;
    overflow-y: scroll;

    // background: #fff;
    margin: 0 auto;
  }
  .contern::-webkit-scrollbar {
    display: none;
  }
  .top_fontSize {
    margin-top: 20px;
    width: 100%;
    font-size: 34px;
    display: block;
    font-weight: 600;
    text-align: center;
  }
  .contern-show {
    margin: 5vh 0 0 10vh;
    .el-input {
      width: 380px;
    }
    .el-textarea__inner {
      width: 380px;
      background: red;
    }
    .boxImg {
      height: 148px;
      width: auto;
      position: relative;
      .logoImg {
        width: auto;
        height: 100%;
      }
      .addImg {
        width: auto;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        opacity: 0;
      }
    }
  }
  .but_commom {
    margin-left: 20px;
  }
  .show_box {
    width: 100%;
    display: flex;
    justify-content: space-around;
    .show_left {
      width: 70%;
    }
    .show_right {
      width: 500px;
      height: 100px;
      background: red;
    }
  }
  .proposerImg_box {
    height: 148px;
    width: auto;
    position: relative;
    .proposerImg {
      height: 100%;
      width: auto;
    }
    .file_box {
      width: auto;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      opacity: 0;
    }
  }
  .add_button {
    width: 200px;
    margin: 30px auto;
    button {
      width: 100%;
    }
  }
}
</style>