<template>
  <div class="home_box">
    <!-- 版心容器 -->
    <div class="container">
      <div style="height: 129px"></div>
      <!-- 首页图内容，点击下载盒子 -->
      <div class="contentHome_box">
        <div class="contentHome_img"></div>
        <span style="font-size: 120px; width: 240px; margin: auto">触音</span>
        <span style="font-size: 36px; width: 690px; margin: auto">好看的皮囊千篇一律,有趣的灵魂万里挑一</span>
        <!-- 下载图标 -->
        <!-- <div class="download_map">
          <a href>
            <div></div>
            <div></div>
            <div></div>
          </a>
        </div> -->
      </div>
    </div>
    <!-- 人物星环 -->
    <div class="enchanter_box">
      <img src="../assets/img/人物星环.png" />
      <div class="magicCircle_text">
        <span>声音</span>
        <span>认识彼此,</span>
        <span>邂逅美好,</span>
        <span>找到合拍的Ta</span>
      </div>
    </div>
    <div class="container">
      <!-- 下单开黑盒子 -->
      <div class="orderShow">
        <span>下单</span>
        <span>开黑，聊天，叫醒，陪玩，总有意想不到的惊喜</span>
      </div>
    </div>
    <!-- 二维码定位 Id用于跳转-->
    <div class="orderShow_imgBox">
      <img src="../assets/img/下单展示图.png" />
    </div>
    <!-- logo图标-->
    <div class="container">
      <div class="logo_perch">
        <img src="../assets/img/logo备份 10.png" />
        <span>游戏 · 交友 · 连麦</span>
      </div>
    </div>
    <!-- 下载二维码 -->
    <div class="qrCode" id="skip">
      <span @click="AndroidDownload"></span>
      <span @click="iosDownload"></span>
      <span @click="thisDownload"></span>
      <img src="../assets/img/下载二维码.png" />
      <img class="aaa" src="../assets/img/WechatIMG5889.jpeg" alt />
      <img class="aaa1" src="../assets/img/WechatIMG5889.jpeg" alt />
      <img class="aaa2" src="../assets/img/WechatIMG5889.jpeg" alt />
    </div>
    <!--备案 -->
    <div class="internet_content">
      <span>©2019-2022 触音 . All rights reserved.</span>
      <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备19079774号</a>
      <span>| 深圳市欢时科技有限公司</span>
    </div>

    <!-- 公众号 -->
    <div class="officiala_acounts">
      <div></div>
      <span>
        扫描二维码，关注公众号
        <br />获取更多活动质讯
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    // 点击安卓下载
    AndroidDownload() {
      location.href="/home/h5/downloadAPP.html"
      // window.open(
      //   "https://funtime-1300805214.file.myqcloud.com/apk/prod/funtime-release.apk"
      // );
    },
    // 点击iOS下载
    iosDownload() {
      location.href="/home/h5/downloadAPP.html"
      
      // window.open("https://funtime-1300805214.cos.ap-shanghai.myqcloud.com/apk/ios/funtime-ios.ipa");
    },
    // 点击本地下载
    thisDownload() {
      location.href="/home/h5/downloadAPP.html"
      // window.open(
      //   "https://funtime-1300805214.file.myqcloud.com/apk/prod/funtime-release.apk"
      // );
    },
  },
};
</script>

<style lang="less" scoped>
// 首页最大盒子
.home_box {
  position: relative;
  box-sizing: border-box;
  background-image: url("../assets/img/rzyouWebsiteBack-up3.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  // height: 100%;
  min-width: 1000px;
  background-attachment: fixed;
  .container {
    width: 1000px;
    color: #fff;
    margin: auto;
  }
  //  首页图内容，点击下载盒子
  .contentHome_box {
    // 首页图
    .contentHome_img {
      width: 68.2%;
      background-repeat: no-repeat;
      height: 340px;
      background-image: url("../assets/img/首页图.png");

      margin: 20px auto;
    }
    span {
      display: block;
    }

    // 下载图标
    .download_map {
      a {
        display: block;
        height: 76px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        div {
          cursor: pointer;
          width: 27.1%;
          height: 76px;
        }
        div:nth-child(1) {
          background-image: url("../assets/img/编组2.png");
        }
        div:nth-child(2) {
          background-image: url("../assets/img/编组 3.png");
        }
        div:nth-child(3) {
          background-image: url("../assets/img/编组 4.png");
        }
      }
    }
  }
  // 人物星环
  .enchanter_box {
    position: relative;
    width: 90%;
    margin: 100px 0 0 10%;
    min-height: auto;
    img {
      width: 100%;
      min-height: auto;
    }
    .magicCircle_text {
      position: absolute;
      top: 0px;
      left: 0px;
      span {
        font-size: 48px;
        color: #fff;
        display: block;
      }
      span:nth-child(1) {
        font-size: 80px;
      }
    }
  }
  // 下单开黑
  .orderShow {
    margin-top: 118px;
    width: 100%;
    span:nth-child(1) {
      font-size: 80px;
      display: block;
      text-align: center;
    }
    span:nth-child(2) {
      font-size: 42px;
      display: block;
      text-align: center;
      padding-bottom: 40px;
    }
  }
  // 下单开黑展示图
  .orderShow_imgBox {
    margin: auto;
    width: 80%;
    // min-width: 1000px;
    img {
      width: 100%;
    }
  }
  // logpo图标
  .logo_perch {
    text-align: center;
    width: 50%;
    margin: 128px auto 0 auto;
    img {
      margin: auto;
      display: block;
      width: 36%;
      height: auto;
    }
    span {
      font-size: 36px;
      line-height: 140px;
    }
  }
  //下载二维码
  .qrCode {
    position: relative;
    margin: auto;
    width: 60%;
    height: auto;
    img {
      width: 100%;
      height: auto;
    }
    .aaa {
      width: 20%;
      position: absolute;
      top: 11%;
      left: 50%;
      transform: translateX(-50%);
      padding:10px ;
      background-color: #fff;
      border-radius: 10px;
    }
    .aaa1 {
      width: 20%;
      position: absolute;
      top: 11%;
      left: 84%;
      transform: translateX(-50%);
      padding:10px ;
      background-color: #fff;
      border-radius: 10px;
    }
    .aaa2 {
      width: 20%;
      position: absolute;
      top: 11%;
      left: 16%;
      transform: translateX(-50%);
      padding:10px ;
      background-color: #fff;
      border-radius: 10px;
    }
    span {
      position: absolute;

      display: block;
      cursor: pointer;

      width: 24.4%;
      height: 15.8%;
      border-radius: 32px;
    }
    span:nth-child(1) {
      bottom: 10%;
      left: 3.8%;
    }
    span:nth-child(2) {
      bottom: 10%;
      left: 50%;
      transform: translateX(-49.2%);
    }
    span:nth-child(3) {
      bottom: 10%;
      right: 3.8%;
    }
  }
  // 备案
  .internet_content {
    margin-top: 40px;
    width: 100%;
    height: 114px;
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 24px;
    line-height: 114px;
    text-align: center;
    a {
      text-decoration: none;
      color: #fff;
    }
    a:hover {
      text-decoration: underline;
    }
  }
  // 公众号
  .officiala_acounts {
    position: fixed;
    right: 30px;
    bottom: 100px;
    padding: 5px;
    width: 214px;
    height: 260px;
    div {
      width: 214px;
      height: 214px;
      background-image: url("../assets/img/公众号二维码.png");
      background-repeat: no-repeat;
    }
    span {
      display: inline-block;
      width: 100%;
      height: calc(260px - 214px);
      font-size: 18px;
      color: #fff;
      text-align: center;
    }
  }
}
</style>