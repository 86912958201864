<template>
  <div class="about_playBox">
    <!-- 版心容器 -->
    <div class="container">
      <div style="height: 128px"></div>

      <!-- 列表大盒子 -->
      <div class="list_box">
        <div
          v-for="(type, index) in typeList"
          :key="index"
          @click="typeLists(type.typeID, index)"
        >
          <!-- 点击切换状态，对应数据源-->
          <img :src="statusImg == index ? type.click_typeImg : type.typeImg" />
          <span>{{ type.typeName }}</span>
        </div>
      </div>
    </div>
    <!-- 展示盒子 -->
    <div class="show_list">
      <div
        class="ilka_content"
        v-for="(item, index) in list"
        :key="index"
        :class="{
          show91: item.tagId == 91,
          show92: item.tagId == 92,
          show93: item.tagId == 93,
          show94: item.tagId == 94,
          show95: item.tagId == 95,
        }"
      >
        <img :src="item.portraitAddress" />
        <div class="logoIco"></div>
        <div class="font_box">
          <span>{{ item.nickname }}</span>
          <span>{{ item.tagName }}</span>
          <span>{{ item.price }}</span>
        </div>
        <div
          class="orthogonImg"
          :class="{
            orthogonId91: item.tagId == 91,
            orthogonId92: item.tagId == 92,
            orthogonId93: item.tagId == 93,
            orthogonId94: item.tagId == 94,
            orthogonId95: item.tagId == 95,
          }"
        >
          {{
            item.tagId == 91
              ? "游戏"
              : "" || item.tagId == 92
              ? "聊天"
              : "" || item.tagId == 93
              ? "叫醒"
              : "" || item.tagId == 94
              ? "陪玩"
              : "" || item.tagId == 95
              ? "其他"
              : ""
          }}
        </div>
      </div>
    </div>

    <div class="reference_box">
      <span
        >©2019-2022 触音 . All rights reserved. 粤ICP备19079774号 |
        深圳市欢时科技有限公司</span
      >
      <span>邮箱：9911107@qq.com丨微信：chuyu52099</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hasMore: true,
      statusImg: 0,
      jsons: {
             param: {
          tagId: 0,
          pageSize: 10,
          startPage:1,
          lastId: 0,
        },
      },
      typeList: [
        {
          typeName: "全部",

          typeImg: require("../assets/img/下单-全部备份.png"),
          click_typeImg: require("../assets/img/下单-全部.png"),
          
          typeID: 0,
        },
        {
          typeName: "游戏",
          typeImg: require("../assets/img/下单-游戏备份.png"),
          click_typeImg: require("../assets/img/下单-游戏.png"),
          typeID: 91,
        },
        {
          typeName: "聊天",
          typeImg: require("../assets/img/下单-聊天备份.png"),
          click_typeImg: require("../assets/img/下单-聊天.png"),
          typeID: 92,
        },
        {
          typeName: "陪玩",
          typeImg: require("../assets/img/下单-陪玩备份.png"),
          click_typeImg: require("../assets/img/下单-陪玩.png"),
          typeID: 94,
        },
        {
          typeName: "叫醒",
          typeImg: require("../assets/img/下单-叫醒备份.png"),
          click_typeImg: require("../assets/img/下单-叫醒.png"),
          typeID: 93,
        },
        {
          typeName: "其他",
          typeImg: require("../assets/img/下单-其他备份.png"),
          click_typeImg: require("../assets/img/下单-其他.png"),
          typeID: 95,
        },
      ],
      list: [],
    };
  },
  methods: {
    //   获取页面展示的列表
    typeLists(type = 0, index = 0) {
     
      if (type !== this.jsons.param.tagId) {
               this.list = [];
               this.jsons.param.startPage=1
      }

      this.statusImg = index;

      this.jsons.param.tagId=type

  
     this.getList()
     
    },
   getList(){
         // this.jsons.param.tagId=get
         
   this.$axios
        .post(
          "/login/getOrderList",
          JSON.stringify(this.jsons)
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.isloading = false;
            this.list = this.list.concat(res.data.data.orderList);
            this.jsons.param.lastId= res.data.data.lastId;
            if (res.data.data.orderList.length <10) {
              this.hasMore = false;
            } else {
              this.hasMore = true;
            }
          }
        });
   },
    LoadMore() {
      window.addEventListener(
        "scroll",
        (e) => {
          let a = document.documentElement.scrollTop || document.body.scrollTop;
          let b = document.documentElement.clientHeight;
          let c = document.documentElement.offsetHeight;

          if (c - b <= a +80&& !this.isloading && this.hasMore) {
            this.jsons.param.startPage += 1;
           
            this.getList()
            this.isloading = true;
          }
           

        },
        true
      );
    },
  },
  created() {
    this.getList();
  },
  mounted() {
    this.LoadMore();
  },
};
</script>

<style lang="less" scoped>
body {
  overflow-y: scroll;
}
// 内容盒子
.about_playBox {
  // 版心容器
  .container {
    width: 1000px;
    margin: auto;
  }
  // 筛选列表盒子
  .list_box {
    width: 80%;
    display: flex;
    justify-content: space-around;
    margin: auto;
    div {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      width: 10%;
      height: 120px;
    }

    img {
      width: 94;
      height: 94px;
      cursor: pointer;
    }
  }
  // 展示列表盒子
  .show_list {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    margin: auto;
    min-width: 1000px;
    justify-content: space-between;

    .ilka_content {
      position: relative;
      margin-top: 20px;
      box-sizing: border-box;
      width: 190px;
      border-radius: 10%;
      height: 260px;
      background-size: 100% 100%;
      background-repeat: no-repeat;

      img {
        width: 100%;
        border-radius: 10% 10% 0 0;
      }

      .logoIco {
        background-image: url("../assets/img/房间备份.png");
        background-size: 100% 100%;
        width: 40px;
        height: 40px;
        position: absolute;
        right: 20px;
        bottom: 45px;
      }
      .font_box {
        color: #fff;
        span:nth-child(1) {
          position: absolute;
          bottom: 32px;
          left: 10px;
        }
        span:nth-child(2) {
          position: absolute;
          bottom: 10px;
          left: 10px;
        }
        span:nth-child(3) {
          position: absolute;
          bottom: 10px;
          right: 10px;
        }
      }
      .orthogonImg {
        color: #fff;
        text-align: center;
        font-size: 10px;
        line-height: 20px;
        width: 50px;
        height: 20px;
        position: absolute;
        top: 15px;
        left: 15px;
        background-size: 100% 100%;
      }
      .orthogonId91 {
        background-image: url("../assets/img/矩形备份91.png");
      }
      .orthogonId92 {
        background-image: url("../assets/img/矩形备份92.png");
      }
      .orthogonId93 {
        background-image: url("../assets/img/矩形备份93.png");
      }
      .orthogonId94 {
        background-image: url("../assets/img/矩形备份94.png");
      }
      .orthogonId95 {
        background-image: url("../assets/img/矩形备份95.png");
      }
    }
    // 背景颜色图
    .show91 {
      background-image: url("../assets/img/3.png");
    }
    .show92 {
      background-image: url("../assets/img/1.png");
    }
    .show93 {
      background-image: url("../assets/img/5.png");
    }
    .show94 {
      background-image: url("../assets/img/2.png");
    }
    .show95 {
      background-image: url("../assets/img/4.png");
    }
  }

  .reference_box {
    position: relative;
    bottom: 0;
    color: #999;
    margin-top: 54px;
    font-size: 20px;
    min-width: 1000px;
    width: 100%;

    span {
      text-align: center;
      display: block;
    }
  }
}
</style>