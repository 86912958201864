<template>
  <div class="content">
    <div style="width: 400px; margin: auto">
      <el-form
        :model="data"
        class="demo-form-inline"
        :rules="rule"
        style="width: 400px"
        ref="rebateData"
        disabled
      >
        <el-form-item label="公会总返点额度" prop="totalPercent">
          <el-input disabled v-model.number="data.totalPercent"
            ><span slot="suffix">%</span>
          </el-input>
        </el-form-item>
        <el-form-item label="其中自动返点到房主" prop="autoRoomPercent">
          <el-input v-model.number="data.autoRoomPercent"
            ><span slot="suffix">%</span></el-input
          >
        </el-form-item>
        <el-form-item label="剩余返点到会长" prop="leftPresidentPencent">
          <el-input v-model.number="data.leftPresidentPencent"
            ><span slot="suffix">%</span></el-input
          >
        </el-form-item>
        <el-form-item label="剩余返点到房主" prop="leftRoomPercent">
          <el-input v-model.number="data.leftRoomPercent"
            ><span slot="suffix">%</span></el-input
          >
        </el-form-item>
        <div style="font-size: 12px; color: red; padding-bottom: 20px">
          自动返点+剩余返点到会长+剩余返点到房主=工会总返点额度
        </div>
        <!-- <el-form-item>
          <div style="width: 400px"></div>
          <el-button type="primary" style="width: 400px" @click="save"
            >保存</el-button
          >
        </el-form-item> -->
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "VueManSystem401Index",

  data() {
    return {
      // params: {},
      data: {},
      rule: {
        totalPercent: [
          {
            type: "number",
            required: true,
            mess: "请填写数字",
            trigger: "change",
          },
        ],
        autoRoomPercent: [
          {
            type: "number",
            required: true,
            mess: "请填写数字",
            trigger: "change",
          },
        ],
        leftPresidentPencent: [
          {
            type: "number",
            required: true,
            mess: "请填写数字",
            trigger: "change",
          },
        ],
        leftRoomPercent: [
          {
            type: "number",
            required: true,
            mess: "请填写数字",
            trigger: "change",
          },
        ],
      },
    };
  },

  mounted() {
    this.search();
  },

  methods: {
    search() {
      this.$axios
        .post(
          "/guild/getGuildInfoForWeb",
          {},
          {
            headers: { token: this.$store.state.usreinf.token },
          }
        )
        .then((res) => {
          
          if (res.data.code == 0) {
            this.data = {
              ...res.data.data,
              totalPercent: res.data.data.totalPercent * 100,
              autoRoomPercent: res.data.data.autoRoomPercent * 100,
              leftPresidentPencent: res.data.data.leftPresidentPencent * 100,
              leftRoomPercent: res.data.data.leftRoomPercent * 100,
            };
          }
        });
    },
    save() {
     

      let {
        totalPercent,
        autoRoomPercent,
        leftPresidentPencent,
        leftRoomPercent,
      } = this.data;
      if (
        totalPercent ==
        autoRoomPercent + leftPresidentPencent + leftRoomPercent
      ) {
        this.$refs.rebateData.validate().then((res) => {
          this.data = {
            ...this.data,
            totalPercent: this.data.totalPercent / 100,
            autoRoomPercent: this.data.autoRoomPercent / 100,
            leftPresidentPencent: this.data.leftPresidentPencent / 100,
            leftRoomPercent: this.data.leftRoomPercent / 100,
          };
          this.$axios
            .post(
              "/guild/updateGuild",
              JSON.stringify({
                param: this.data,
              }),
              {
                headers: { token: this.$store.state.usreinf.token },
              }
            )
            .then((cb) => {
              if (cb.data.code == 0) {
                this.$message({
                  message: cb.data.msg,
                  type: "success",
                });
                setInterval(()=> {
                  window.location.reload()
                }, 500);
              } else {
                this.$message.error(cb.data.msg);
                    setInterval(()=> {
                  window.location.reload()
                }, 500);
              }
            });
        });
      } else {
        this.$message({
          message:
            "请输入正确的返点数字,自动返点+剩余返点到会长+剩余返点到房主=工会总返点额度",
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  background: #fff;
  overflow-y: scroll;
}
  .content::-webkit-scrollbar {
    display: none;
  }
</style>