<template>
  <div class="box">
    <div style="height: 128px"></div>
    <div class="Access_background">
      <el-button
        v-if="isshow.guildRole == 1"
        class="position_but"
        @click="getInto"
      >
        进入公会
      </el-button>
      <el-button v-else class="position_but" @click="joinBut">
        立即入会
      </el-button>
      <ShowTxt ref="TextShow" />
    </div>
    <!-- <img src="../assets/img/AddSociety.png" alt=""> -->
  </div>
</template>

<script>
import ShowTxt from "./society/components/SocietyTxt.vue";
export default {
  components: {
    ShowTxt,
  },
  data() {
    return {
      isshow: {},
    };
  },
  methods: {
    joinBut() {
      if (this.$store.state.usreinf.id) {
        let gonghui = JSON.parse(
          sessionStorage.getItem("gonghuixinxi") || "{}"
        );
        if (gonghui.guildApplyState || gonghui.guildJoinApplyState) {
          this.$message({
            message: gonghui.guildApplyState
              ? "您已经提交了创建工会申请，请耐心等待"
              : "您已经提交了加入工会申请，请耐心等待",
            type: "warning",
          });
          return
        }
        this.$refs.TextShow.open();
      } else {
        this.$store.commit("showLoginBox", true);
      }
    },
    getInto() {
      this.$router.push("/MyGuild");
    },
  },

  created() {
    this.isshow = JSON.parse(sessionStorage.getItem("gonghuixinxi") || "{}");
    console.log(this.isshow);
  },
};
</script>

<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}
.box {
  width: 100vw;
  height: 100vh;
}
.Access_background {
  position: relative;
  width: 100vw;
  height: calc(100vh - 128px);
  min-width: 1000px;
  background: url("../assets/img/AddSociety.png") center;
  background-size: cover;
  background-repeat: no-repeat;
  .position_but {
    color: #ffffff;
    // line-height: 62px;
    text-align: center;

    background: linear-gradient(45deg, #e67bfd, #8446fc);

    font-size: 36px;
    cursor: pointer;
    position: absolute;
    bottom: 6.5%;
    // bottom: 77px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 302px;

    height: 62px;
    border-radius: 6px;
  }
}
</style>