<template>
  <div class="contaner">
    <div>
      <el-table :data="returnData" class="audit_box"
      :header-cell-style="{'text-align':'center'}"
    :cell-style="{'text-align':'center'}"
      >
        <el-table-column label="流水结算周期" width="300px">
          <template slot-scope="scope">
            <span> {{ scope.row.startDay }}至{{ scope.row.endDay }} </span>
          </template>
        </el-table-column>
        <el-table-column label="未审批" width="140px">
          <template slot-scope="scope">
            <span>
              {{ scope.row.state == 1 ? scope.row.totalAmount : 0 }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="已审批" width="140px">
          <template slot-scope="scope">
            <span>
              {{
                scope.row.state == 2
                  ? scope.row.totalAmount
                  : 0 || scope.row.state == 3
                  ? scope.row.totalAmount
                  : 0
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="被扣数额" prop="diffAmount" width="140px">
        </el-table-column>
        <el-table-column label="审批备注" width="80px">
          <template slot-scope="scope">
            <span> {{ scope.row.remark == "" ? "/" : scope.row.remark }} </span>
          </template>
        </el-table-column>
        <el-table-column
          label="上一次审批时间"
          prop="lastExamineTime"
          width="250px"
        >
        </el-table-column>
        <el-table-column label="状态" width="80px">
          <template slot-scope="scope">
            <span> {{ scope.row.state | getName }} </span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)"
              >查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      returnData: [],
    };
  },
  filters: {
    getName(state) {
      const stateObj = {
        1: "待审核",
        2: "通过",
        3: "拒绝",
      };
      return stateObj[state];
    },
  },
  methods: {
    handleEdit(row) {
  
      this.$router.push({path:"/roomFlowingWater" ,query:{
        id:row.id
      }})
      
    },
    dataList(){
 this.$axios
      .post(
        "/guild/getGuildTurnoverList",
        {
          param: {},
        },
        {
          headers: { token: this.$store.state.usreinf.token },
        }
      )
      .then((res) => {
        // console.log(res);
        if(res.data.code==0){
        this.returnData=res.data.data.list
        }
        
      });
    }
  },
  created() {
   this.dataList()
  },
};
</script>

<style lang="less" scoped>
.contaner {
  padding: 20px;
    overflow-y: scroll;

}
.contaner::-webkit-scrollbar {
    display: none;
  }
.audit_box {
  margin: auto;
  width: 100%;
  min-width:100%;
}
</style>