<template>
  <div class="container">
    <el-table
      :data="tableData"
      class="audit_box"
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center' }"
    >
      <el-table-column label="房主名称" prop="nickname"> </el-table-column>
      <el-table-column label="房间ID" prop="id"> </el-table-column>
      <el-table-column label="申请时间" prop="createTime"> </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span>{{ scope.row.state | getName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审批时间" prop="updateTime"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.state == 1"
            @click="handleEdit(scope.row, 1)"
            >通过</el-button
          >
          <el-button
            v-if="scope.row.state == 1"
            @click="handleEdit(scope.row, 2)"
            >拒绝</el-button
          >
          <el-button
            v-else-if="scope.row.state == 2"
            @click="handleEdit(scope.row, 3)"
            >解约</el-button
          >
          <span v-else>/</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
    };
  },
  filters: {
    getName(state) {
      const stateObj = {
        1: "待审核",
        2: "通过",
        3: "拒绝",
        4: "解约",
        5: "超时",
        6: "踢出",
        7: "成员申请退出",
      };
      return stateObj[state] || "-";
    },
  },
  methods: {
    handleEdit(row, state) {
      let alertMsg = {
        1:'是否确认给该用户通过',
        2:'是否确认拒绝用户通过',
        3:'是否确认跟该成员解约'
      }
      this.$confirm(alertMsg[state], "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.$axios
            .post(
              "/guild/examineSignApply",
              JSON.stringify({
                param: {
                  id: row.id, //审核记录ID
                  type: state, //1-通过 2-拒绝 3-解约
                },
              }),
              {
                headers: { token: this.$store.state.usreinf.token },
              }
            )
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                setInterval(() => {
                  window.location.reload();
                }, 500);
              } else {
                this.$message.error(res.data.msg);
                setInterval(() => {
                  window.location.reload();
                }, 500);
              }
            });
        })
        .catch((action) => {});
    },
    tableDataList() {
      this.$axios
        .post(
          "/guild/getGuildMemberSignRecords",
          {},
          {
            headers: { token: this.$store.state.usreinf.token },
          }
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.tableData = res.data.data;
          }
        });
    },
    refusedTo() {},
  },
  created() {
    this.tableDataList();
  },
};
</script>

<style lang="less" scoped>
.container {
  padding: 20px;
  overflow-y: scroll;
}
.container::-webkit-scrollbar {
  display: none;
}
.audit_box {
  width: 100%;
  min-width: 1200px;
  margin: auto;
  max-height: 78vh;
  overflow-y: scroll;
}
.audit_box::-webkit-scrollbar {
  display: none;
}
</style>