<template>
  <div>
    <div class="line"></div>
    <div class="upper_level">
      <div @click="upperLevel">查看已上传文件</div>
    </div>

    <div class="box">
      <div class="form">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          label-position="top"
        >
          <el-form-item label="歌曲名称" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="演唱者" prop="singer">
            <el-input v-model="ruleForm.singer"></el-input>
          </el-form-item>
          <el-form-item
            label="音乐文件（必填，仅限MP3格式，不超过20M）"
            prop="musicfiles"
          >
            <input
              type="file"
              class="musicfiles"
              ref="file"
              :model="ruleForm.musicfiles"
            />
          </el-form-item>
          <el-form-item label="若为伴奏，请选择伴奏" prop="resource">
            <el-radio-group v-model="ruleForm.resource">
              <el-radio label="1">伴奏</el-radio>
              <el-radio label="2">非伴奏</el-radio>
            </el-radio-group>
          </el-form-item>
          <!--  -->
          <!--  -->
          <el-checkbox label="1" name="type" class="qwe" v-model="ruleForm.x"
            >同时推荐到共享曲库（需后台审核）</el-checkbox
          >
          <el-checkbox-group v-model="ruleForm.type">
            <el-checkbox label="1" name="type" class="qwe"
              >我已认真阅读并同意 <a href="https://www.rzyou.com/legal.html" target="view_window">《触音用户协议》</a></el-checkbox
            >
          </el-checkbox-group>
        </el-form>
        <el-button type="primary" class="upload_songs" @click="submit"
          >上传</el-button
        >
      </div>
    </div>
    <div class="article">
        <div>©2019-2022 触音 . All rights reserved. 粤ICP备19079774号 | 深圳市欢时科技有限公司</div>
        <div>邮箱：9911107@qq.com丨微信：chuyu52099</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        singer: "",
        musicfiles: "",
        resource: "1",
        type: [],
        x: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "必填，不超过50字符",
            trigger: "blur",
          },
          { min: 1, max: 50, message: "不超过50字符", trigger: "blur" },
        ],
        singer: [
          {
            required: true,
            message: "（必填，不超过30个字，该信息不准确可能导致下架）",
            trigger: "blur",
          },
          { min: 1, max: 30, message: "（不超过30个字）", trigger: "blur" },
        ],
        musicfiles: [
          { required: true, message: "请选择你的音乐文件", trigger: "blur" },
        ],
        resource: [
          {
            required: true,
            message: "请选择是否为伴奏或不伴奏",
            trigger: "change",
          },
        ],
        type: [
          { type: "array", required: true, message: "", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    upperLevel() {
      this.$router.go(-1);
    },
    // 提交
    submit() {
      
      // console.log(this.$refs.file.files[0]);
      // if (
      //   this.$refs.file.files[0] &&
      //   this.$refs.file.files[0].name.slice(-3) != "mp3"
      // ) {
      //   this.$message.error("上传音乐文件须以mp3格式");
      //   return;
      // }
      this.updataFile(this.$refs.file.files[0], (url) => {
          console.log(url)
        let params = {
          param: {
            fileName: this.ruleForm.name,
            author: this.ruleForm.singer,
            musicUrl: url,
            accompany: this.ruleForm.resource,
            shareState: this.ruleForm.x ? 1 : 2,
            flieSize: (this.$refs.file.files[0].size / 1024).toFixed(2) + "kb",
          },
        };
        return 
        // console.log(params);
        this.$axios
          .post(
            "/music/addMusic",
            JSON.stringify(params),
            { headers: { token: this.$store.state.usreinf.token } }
          )
          .then((res) => {
            // console.log(res.data.code);
            if (res.data.code == 0) {
              this.$router.go(-1);
            }
          });
      });
    },
    updataFile(file, cb) {
      var getAuthorization = function (options, callback) {
        // 格式四、（不推荐，适用于前端调试，避免泄露密钥）前端使用固定密钥计算签名
        var authorization = COS.getAuthorization({
          SecretId: "AKID5Zom24pOefwypStiSu6l9G7i25qKETjm", // 可传固定密钥或者临时密钥
          SecretKey: "x71qQHsFNFUS9cpcAbnjRyCF8qfLrDNQ", // 可传固定密钥或者临时密钥
          Method: options.Method,
          Pathname: options.Pathname,
          Query: options.Query,
          Headers: options.Headers,
          Expires: 900,
        });
        // console.log(authorization);
        callback({
          Authorization: authorization,
          // SecurityToken: credentials.sessionToken, // 如果使用临时密钥，需要传 SecurityToken
        });
      };
      var cos = new COS({
        getAuthorization: getAuthorization,
        UploadCheckContentMd5: true,
      });
      let key = `music/${
        this.$store.state.usreinf.id
      }/${new Date().valueOf()}.svga`; //.mp3
      cos.putObject(
        {
          Bucket: "music-1300805214" /* 填入您自己的存储桶，必须字段 */,
          Region: "ap-shanghai" /* 存储桶所在地域，例如ap-beijing，必须字段 */,
          Key: key /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
          Body: file, // 上传文件对象
          onProgress: function (progressData) {},
        },
        function (err, data) {
          if (err) {
            alert("上传文件出错请稍后再试");
          }
        }
      );
      cb("https://music-1300805214.file.myqcloud.com/" + key);
    },
  },
};
</script>

<style lang="less" scoped>
.line {
  margin-top: 128px;
  width: 100%;
  border-top: 1px #ccc solid;
}
.upper_level {
  width: 100%;
  min-width: 1000px;
  margin-top: 20px;
  margin-bottom: 20px;
  div {
    width: 650px;
    margin: auto;
    font-size: 25px;
    color: #347af6;
    text-decoration: underline;
    cursor: pointer;
  }
  div:hover {
    color: #2455ad;
  }
}
.box {
  width: 100%;
  min-width: 1000px;
  .form {
    width: 650px;

    border: #999 1px solid;
    border-radius: 3px;
    margin: auto;
    padding: 20px 30px;
  }
}
.musicfiles::-webkit-file-upload-button {
  display: inline-block;
  width: 100px;
  height: 30px;
  background: #347af6;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.musicfiles::-webkit-file-upload-button:hover {
  opacity: 0.9;
}
.musicfiles::-webkit-file-upload-button:active {
  border: 1px solid #ccc;
  border-radius: 5px;
}
.qwe {
  display: block;
  margin-top: 5px;
  a {
    text-decoration: none;
    color: #347af6;
  }
  a:hover {
    text-decoration: underline;
  }
}
.upload_songs {
  width: 100%;
  margin: 40px 0 0 0;
}
.article{
        width: 1000px;
        margin: auto;
        text-align: center;
        margin-top: 60px;
        div{
            font-size: 18px;
            color: #ccc;
        }
    }
</style>