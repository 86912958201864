var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about_playBox"},[_c('div',{staticClass:"container"},[_c('div',{staticStyle:{"height":"128px"}}),_c('div',{staticClass:"list_box"},_vm._l((_vm.typeList),function(type,index){return _c('div',{key:index,on:{"click":function($event){return _vm.typeLists(type.typeID, index)}}},[_c('img',{attrs:{"src":_vm.statusImg == index ? type.click_typeImg : type.typeImg}}),_c('span',[_vm._v(_vm._s(type.typeName))])])}),0)]),_c('div',{staticClass:"show_list"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"ilka_content",class:{
        show91: item.tagId == 91,
        show92: item.tagId == 92,
        show93: item.tagId == 93,
        show94: item.tagId == 94,
        show95: item.tagId == 95,
      }},[_c('img',{attrs:{"src":item.portraitAddress}}),_c('div',{staticClass:"logoIco"}),_c('div',{staticClass:"font_box"},[_c('span',[_vm._v(_vm._s(item.nickname))]),_c('span',[_vm._v(_vm._s(item.tagName))]),_c('span',[_vm._v(_vm._s(item.price))])]),_c('div',{staticClass:"orthogonImg",class:{
          orthogonId91: item.tagId == 91,
          orthogonId92: item.tagId == 92,
          orthogonId93: item.tagId == 93,
          orthogonId94: item.tagId == 94,
          orthogonId95: item.tagId == 95,
        }},[_vm._v(" "+_vm._s(item.tagId == 91 ? "游戏" : "" || item.tagId == 92 ? "聊天" : "" || item.tagId == 93 ? "叫醒" : "" || item.tagId == 94 ? "陪玩" : "" || item.tagId == 95 ? "其他" : "")+" ")])])}),0),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reference_box"},[_c('span',[_vm._v("©2019-2022 触音 . All rights reserved. 粤ICP备19079774号 | 深圳市欢时科技有限公司")]),_c('span',[_vm._v("邮箱：9911107@qq.com丨微信：chuyu52099")])])}]

export { render, staticRenderFns }