<template>
  <div>
    <div style="height: 128px"></div>
    <div class="body_content">
      <div class="left_nav">
        <span class="society">公会管理</span>
        <div>
          <span
         
            v-for="item in navList"
            :key="item.id"
               :class="{
              backgroundColor: $route.path==item.path,
            }"
            @click="switchs(item.path,item.id)"
          >
            {{ item.content }}
          </span>
        </div>
      </div>
      <div class="right_content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      backg: 1,
      navList: [
        {
          content: "公会资料",
          id: 1,
          path:"/MyGuild"
        },
        {
          content: "签约审核",
          id: 2,
          path:"/Audit"
        },
        {
          content: "返点查询",
          id: 3,
          path:'/Return'
        },
        {
          content: "房间流水",
          id: 3,
          path:'/guildRunningWater'
        },
        {
          content: "返点信息",
          id: 4,
          path:'/Rebate'
        },
      ],
    };
  },
  methods: {
    switchs(routet="/MyGuild",index){
      this.backg=index
      this.$router.push(routet)
    }
  }
};
</script>

<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}
.body_content {
  display: flex;
  box-sizing: border-box;
  border-top: 1px solid #ccc;
  .left_nav {
    box-sizing: border-box;
    border-right: 1px solid #ccc;
    // color: #000;
    height: calc(100vh - 128px);
    
    min-width: 11%;
    line-height: 40px;
    .society {
      margin-left: 20px;
      display: block;
    }
    div {
      span {
        display: block;
        width: 100%;
        text-align: center;
        cursor: pointer;
      }
      span:hover {
        background: #ccc;
      }
    }
  }
  .right_content {
    background: #fff;
    flex: 1;
    height: calc(100vh - 128px);
    margin: 0;
    padding: 0;
    overflow:scroll;
  }
}
.backgroundColor {
  background: #ccc;
}
</style>