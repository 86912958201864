import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Index.vue'
import dynamic from '../views/dynamic.vue'
import AboutPlay from '../views/AboutPlay'
import Uploadmusic from '../views/Uploadmusic.vue'
import fillindata from '../views/fillindata.vue'
import Access from '../views/SocietyAccess.vue'
import SocietyDatum from '../views/society/SocietyInformation.vue'
import SocietyManage from '../views/societyManage/indexManage.vue'
import SocietyData from '../views/societyManage/views/societyData.vue'
import SocietyAudit from '../views/societyManage/views/socuetyAudit.vue'
import SocietyReturn from '../views/societyManage/views/societyReturn.vue'
import societyRebate from '../views/societyManage/views/societyRebate.vue'
import roomFlowingWater from '../views/societyManage/views/roomFlowingWater.vue'
import guildRunningWater from '../views/guildRunningWater/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/dynamic',
    name: 'dynamic',
    component:dynamic,
  },
  {
    path: '/AboutPlay',
    name: 'AboutPlay',
    component:AboutPlay,
  },
  {
    path: '/Uploadmusic',
    name: 'Uploadmusic',
    component:Uploadmusic,
  },
  {
    path: '/fillindata',
    name: 'fillindata',
    component:fillindata,
  },
  {
    path: '/Access',
    name: 'Access',
    component:Access,
    meta:{
      // isLogin:true
    }
  },
  {
    path: '/SocietyDatum',
    name: 'SocietyDatum',
    component:SocietyDatum,
    meta:{
      isLogin:true
    }
  },
  {
    path: '/SocietyManage',
    name: 'SocietyManage',
    component:SocietyManage,
    meta:{
      isLogin:true
    },
    children:[
      // 公会资料
      {
        path: '/MyGuild',
        name: 'SocietyData',
        component:SocietyData,
        meta:{
          isLogin:true
        }
      },
      // 返点查询
      {
        path: '/Return',
        name: 'SocietyReturn',
        component:SocietyReturn,
        meta:{
          isLogin:true
        }
      },
      // 公会审核
      {
        path: '/Audit',
        name: 'SocietyAudit',
        component:SocietyAudit,
        meta:{
          isLogin:true
        }
      },
      {
        path: '/guildRunningWater',
        name: 'SocietyAudit',
        component:guildRunningWater,
        meta:{
          isLogin:true
        }
      },
      
      {
        path: '/Rebate',
        name: 'societyRebate',
        component:societyRebate,
        meta:{
          isLogin:true
        }
      },
      {
        path: '/roomFlowingWater',
        name: 'roomFlowingWater',
        component:roomFlowingWater,
        meta:{
          isLogin:true
        }
      },
      
    ]
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
const routerRePush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
  return routerRePush.call(this, location).catch(error => error)
}

export default router
