<template>
  <div class="datam_box">
    <div style="height: 128px"></div>
    <div class="switch_box">
      <div class="switch_top">
        <span
          :class="{
            backdrop1: backdropShow == index,
            backdrop2: backdropShow > index,
          }"
          v-for="(item, index) in switchList"
          :key="index"
        >
          {{ item.switchName }}
        </span>
      </div>
      <div class="switch_font">
        <span
          >为确保公会顺利入驻，请务必如实填写以下内容，否则申请将无法通过平台审核，<span
            style="color: red"
            >*</span
          >
          为必填项，所有内容提交后不可修改。</span
        >
      </div>

      <div class="container">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-position="left"
          label-width="120px"
        >
          <!-- 基本信息盒子 -->
          <div class="basic_box common" v-if="backdropShow == 0">
            <el-form-item label="公会名称" prop="societyName">
              <el-input
                maxlength="8"
                v-model="ruleForm.societyName"
                placeholder="请填写公会名称，不超过8个字符"
              ></el-input>
            </el-form-item>
            <el-form-item label="公会简介" prop="societyIntro">
              <el-input
                style="width: 440px"
                class="society_Intro"
                type="textarea"
                maxlength="100"
                :autosize="{ minRows: 4 }"
                show-word-limit
                v-model="ruleForm.societyIntro"
                placeholder="请填写公会简介，不超过100个字符"
              ></el-input>
            </el-form-item>
            <!-- 图片上传 -->
            <el-form-item label="公会LOGO" prop="societyLogo">
              <div class="logoStyle" v-if="!ruleForm.societyLogo">
                <span></span>
                <span></span>
                <input
                  type="file"
                  class="fileLogo"
                  @change="
                    (e) => {
                      changFile(e, 'societyLogo');
                    }
                  "
                />
              </div>
              <div v-else class="logoStyle2">
                <img :src="ruleForm.societyLogo" alt="" />
              </div>
            </el-form-item>
            <el-form-item label="会长姓名" prop="cdrName">
              <el-input
                placeholder="请填写申请人姓名，需要与申请人身份证照片一致"
                v-model="ruleForm.cdrName"
              ></el-input>
            </el-form-item>
            <el-form-item label="会长身份证" prop="cdrId">
              <el-input
                maxlength="18"
                v-model="ruleForm.cdrId"
                placeholder="请填写申请人身份证号码，需要与申请人身份证照片一致"
              ></el-input>
            </el-form-item>
            <el-form-item label="公会主体类型" prop="subjectType">
              <el-radio v-model="ruleForm.subjectType" label="1">个人</el-radio>
              <el-radio v-model="ruleForm.subjectType" label="2">企业</el-radio>
            </el-form-item>
            <el-form-item
              v-if="ruleForm.subjectType == 2"
              label="企业名称"
              prop="companyName"
            >
              <el-input
                v-model="ruleForm.companyName"
                placeholder="请填写企业名称，需要与营业执照图片一致"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="ruleForm.subjectType == 2"
              label="企业执照号"
              prop="companyId"
            >
              <el-input
                v-model="ruleForm.companyId"
                placeholder="请填写营业执照号，需要与营业执照号一致 "
              ></el-input>
            </el-form-item>
            <div class="basic_but">
              <el-button @click="nextStep" class="but_blue">下一步</el-button>
            </div>
          </div>
          <!-- 材料上传盒子 -->
          <div v-if="backdropShow == 1" class="common materials">
            <el-form-item
              label="申请人身份证正面"
              label-width="150px"
              prop="proposerJustImg"
            >
              <div>
                <div class="idPicture" v-if="!ruleForm.proposerJustImg">
                  <span></span>
                  <span></span>
                  <input
                    type="file"
                    class="uploadingIMG"
                    @change="
                      (e) => {
                        changFile(e, 'proposerJustImg');
                      }
                    "
                  />
                </div>
                <div v-else class="showImg">
                  <img :src="ruleForm.proposerJustImg" />
                </div>
              </div>
              <span class="imgFont">jgp或png，大小不超过10M</span>
            </el-form-item>
            <el-form-item
              label="申请人身份证反面"
              label-width="150px"
              prop="proposerContraryImg"
            >
              <div>
                <div class="idPicture" v-if="!ruleForm.proposerContraryImg">
                  <span></span>
                  <span></span>
                  <input
                    type="file"
                    class="uploadingIMG"
                    @change="
                      (e) => {
                        changFile(e, 'proposerContraryImg');
                      }
                    "
                  />
                </div>
                <div v-else class="showImg">
                  <img :src="ruleForm.proposerContraryImg" />
                </div>
              </div>
              <span class="imgFont">jgp或png，大小不超过10M</span>
            </el-form-item>

            <el-form-item
              v-if="ruleForm.subjectType == 2"
              label="营业执照图片"
              label-width="150px"
              prop="companyIdImg"
            >
              <div>
                <div class="idPicture" v-if="!ruleForm.companyIdImg">
                  <span></span>
                  <span></span>
                  <input
                    type="file"
                    class="uploadingIMG"
                    @change="
                      (e) => {
                        changFile(e, 'companyIdImg');
                      }
                    "
                  />
                </div>
                <div v-else class="showImg">
                  <img :src="ruleForm.companyIdImg" />
                </div>
              </div>
              <span class="imgFont">jgp或png，大小不超过10M</span>
            </el-form-item>
            <div class="but_box">
              <el-button @click="esc" class="esc_but">返回</el-button>
              <el-button @click="nextStep" class="but_blue">下一步</el-button>
            </div>
          </div>
          <!-- 联系方式盒子 -->
          <div v-if="backdropShow == 2" class="common">
            <el-form-item label="联系人姓名" prop="contactsName">
              <el-input
                v-model="ruleForm.contactsName"
                placeholder="请填写联系人姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="contactsPhone">
              <el-input
                v-model.number="ruleForm.contactsPhone"
                maxlength="11"
                placeholder="请填写联系电话"
              ></el-input>
            </el-form-item>
            <el-form-item label="QQ号" prop="contactsQq">
              <el-input
                placeholder="请填写QQ号"
                v-model.number="ruleForm.contactsQq"
              ></el-input>
            </el-form-item>
            <el-form-item label="微信" prop="contactsVx">
              <el-input
                placeholder="请填写微信号"
                v-model="ruleForm.contactsVx"
              ></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="securityCode">
              <el-input
                placeholder="请输入验证码"
                class="security_code"
                v-model="ruleForm.securityCode"
              >
              </el-input>

              <el-button v-show="x" class="verify1" @click="information"
                >获得验证码</el-button
              >
              <span v-show="!x" class="verify2"
                >{{ countDown }}秒后重新获取</span
              >
            </el-form-item>
            <div class="but_box">
              <el-button @click="esc" class="esc_but">返回</el-button>
              <el-button
                v-if="
                  ruleForm.contactsName &&
                  ruleForm.contactsPhone &&
                  ruleForm.contactsQq &&
                  ruleForm.contactsVx &&
                  ruleForm.securityCode
                "
                @click="offer"
                class="but_blue"
                >提交</el-button
              >
              <el-button v-else class="verify3">提交</el-button>
            </div>
            <span class="statement">此次申请需提交保证金100,000钻石</span>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      countDown: 30,
      x: true,
      securityCode: "",
      // 传入字段
      ruleForm: {
        //   身份证正面
        proposerJustImg: "",
        // 身份证反面
        proposerContraryImg: "",
        // 营业执照
        // companyIdImg: "",
        //   公会名称
        //   societyName:"",
        //   公会简介
        //   societyIntro:'',
        //   公会logo
        societyLogo: "",
        //   公会主体状态 1为个人 2为企业 默认为2
        subjectType: "2",
        // //   企业名称
        // companyName:"",
        // // 企业执照号
        // companyId:"",
      },
      // 校验规则
      rules: {
        securityCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        companyIdImg: [
          { required: true, message: "请上传营业执照", trigger: "change" },
        ],
        proposerJustImg: [
          { required: true, message: "请上传身份证正面", trigger: "change" },
        ],
        proposerContraryImg: [
          { required: true, message: "请上传身份证反面", trigger: "change" },
        ],
        societyName: [
          { required: true, message: "请输入公会名称", trigger: "blur" },
          { min: 2, max: 8, message: "公会名称在2-8个字符", trigger: "blur" },
        ],
        societyIntro: [
          { required: true, message: "请输入公会简介", trigger: "blur" },
          {
            min: 5,
            max: 100,
            message: "公会简介长度不低于5大于100",
            trigger: "blur",
          },
        ],
        societyLogo: [
          { required: true, message: "请上传公会LOGO", trigger: "change" },
        ],
        cdrName: [
          { required: true, message: "请输入会长名字", trigger: "blur" },
        ],
        cdrId: [
          { required: true, message: "请输入会长身份证", trigger: "blur" },
          {
            min: 18,
            max: 18,
            message: "身份证长度为18",
            trigger: "blur",
          },
        ],
        subjectType: [
          { required: true, message: "请选择公会主体", trigger: "blur" },
        ],
        companyName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        companyId: [
          { required: true, message: "请输入企业执照号", trigger: "blur" },
        ],
        contactsName: [
          { required: true, message: "请填写联系人姓名", trigger: "blur" },
        ],
        contactsPhone: [
          { type: "number", message: "请正确输入电话号码", trigger: "blur" },
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
        contactsQq: [
          { type: "number", message: "请正确输入QQ号", trigger: "blur" },
          { required: true, message: "请输入QQ号", trigger: "blur" },
        ],
        contactsVx: [
          { required: true, message: "请输入微信号", trigger: "blur" },
        ],
      },
      switchList: [
        {
          switchName: "1. 基本信息",
        },
        {
          switchName: "2. 材料上传",
        },
        {
          switchName: "3. 联系方式",
        },
      ],
      backdropShow: 0,
    };
  },
  methods: {
    //   上传图片的方法，默认传入e，e为该元素 key为名字
    changFile(e, key) {
      
      if(e.target.files[0].size/1024/1024>10){
                 this.$message.error('图片大小只支持10M以内');
      
      }else{
   // 调用方法需要传该元素files的下标第一个给他，然后返回一个url
      this.updataFile(e.target.files[0], (url) => {
        // 返回的url赋值给ruleForm对象里相应的key名字
        this.ruleForm[key] = url;
        // setTimeout(()=>{
        // this.ruleForm.proposerJustImg = url
        // },2000)
      });
      }
   
    },
    // 获取验证码
    information() {
      var lo = {
        token: "",
        version: "1.0",
        param: {
          phoneNumber: this.ruleForm.contactsPhone,
          resend: "1",
          smsType: 6,
        },
      };
      if (/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.ruleForm.contactsPhone)) {
        this.$axios
          .post("/login/sendSms", JSON.stringify(lo))
          .then((res) => {
          
            this.x = false;
            var a = setInterval(() => {
              this.countDown--;
              if (this.countDown == 0) {
                clearInterval(a);
                this.x = true;
                setTimeout(() => {
                  this.countDown = 30;
                }, 500);
              }
            }, 1000);
          });
      } else {
        this.$message("手机号码有误！");
      }
    },
    // 下一步按键
    nextStep() {
      // console.log(123)
      this.$refs.ruleForm
        .validate()
        .then((res) => {
 
          if (res) {
            this.backdropShow++;
            if (this.backdropShow > 2) {
              this.backdropShow = 2;
            }
          }
        })
  
    },
    // 返回键
    esc() {
      this.backdropShow--;
      if (this.backdropShow < 0) {
        this.backdropShow = 0;
      }
    },
    // 提交按钮
    offer() {
      // console.log(this.$refs.ruleForm.model);
      this.$refs.ruleForm
        .validate()
        .then((res) => {
          let params = {
            param: {
              businessNumber: this.$refs.ruleForm.model.companyId,
              contactName: this.$refs.ruleForm.model.contactsName,
              contactPhone: this.$refs.ruleForm.model.contactsPhone,
              guildType: this.$refs.ruleForm.model.subjectType,
              identityCard: this.$refs.ruleForm.model.cdrId,
              companyName: this.$refs.ruleForm.model.companyName,
              createUserName: this.$refs.ruleForm.model.cdrName,
              guildName: this.$refs.ruleForm.model.societyName,
              identityCardFronturl: this.$refs.ruleForm.model.proposerJustImg,
              identityCardBackurl:
                this.$refs.ruleForm.model.proposerContraryImg,
              businessUrl: this.$refs.ruleForm.model.companyIdImg,
              logoUrl: this.$refs.ruleForm.model.societyLogo,
              qq: this.$refs.ruleForm.model.contactsQq,
              wx: this.$refs.ruleForm.model.contactsVx,
              code: this.$refs.ruleForm.model.securityCode,
              signText: this.$refs.ruleForm.model.societyIntro,
            },
          };

          this.$axios
            .post(
              "/guild/saveGuild",
              JSON.stringify(params),
              {
                headers: { token: this.$store.state.usreinf.token },
              }
            )
            .then((res) => {
              if (res.data.code == '0') {
                this.$message({
                  message: res.msg,
                  type: "success",
                });

                this.$message.error(cb.data.msg);
                setInterval(() => {
                  this.$router.push("/");
                }, 500);
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                });
              }
            });
        })

    },
    updataFile(file, cb) {
      var getAuthorization = function (options, callback) {
        // 格式四、（不推荐，适用于前端调试，避免泄露密钥）前端使用固定密钥计算签名
        var authorization = COS.getAuthorization({
          SecretId: "AKID5Zom24pOefwypStiSu6l9G7i25qKETjm", // 可传固定密钥或者临时密钥
          SecretKey: "x71qQHsFNFUS9cpcAbnjRyCF8qfLrDNQ", // 可传固定密钥或者临时密钥
          Method: options.Method,
          Pathname: options.Pathname,
          Query: options.Query,
          Headers: options.Headers,
          Expires: 900,
        });
        // console.log(authorization);
        callback({
          Authorization: authorization,
          // SecurityToken: credentials.sessionToken, // 如果使用临时密钥，需要传 SecurityToken
        });
      };
      var cos = new COS({
        getAuthorization: getAuthorization,
        UploadCheckContentMd5: true,
      });
      let key = `music/${
        this.$store.state.usreinf.id
      }/${new Date().valueOf()}.png`; //.mp3
      cos.putObject(
        {
          Bucket: "music-1300805214" /* 填入您自己的存储桶，必须字段 */,
          Region: "ap-shanghai" /* 存储桶所在地域，例如ap-beijing，必须字段 */,
          Key: key /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
          Body: file, // 上传文件对象
          onProgress: function (progressData) {
            if (progressData.percent == 1) {
              cb("https://music-1300805214.file.myqcloud.com/" + key);
            }
          },
        },
        function (err, data) {
          if (err) {
            alert("上传文件出错请稍后再试");
          }
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.datam_box {
  min-width: 1200px;
  width: 100%;
}
.container {
  width: 1000px;
  margin: auto;
  .el-input {
    width: 440px;
    z-index: 2;
  }
}
.switch_box {
  border-radius: 10px;
  box-sizing: border-box;
  width: 1000px;
  margin: auto;

  .switch_top {
    background: #ebebeb;
    display: flex;
    height: 80px;
    width: 100%;
    margin: auto;
    color: #8d8e99;
    border-radius: 15px;
    span {
      display: block;
      width: 33.3333%;
      text-align: center;
      line-height: 80px;
      height: 100%;

      font-size: 20px;
    }
    span:nth-child(1) {
      border-radius: 15px 0 0 15px;
    }
    span:nth-child(3) {
      border-radius: 0 15px 15px 0;
    }
  }

  // 渐变图片
  .backdrop1 {
    // background-image: url("../../assets/img/rectangleGradient.png");

    background: linear-gradient(
      90deg,
      #8446fc 0%,
      #8446fc 0%,
      #e67bfd 100%,
      #e67bfd 100%
    );
    background-size: 100% 100%;
    color: #fff;
    background-repeat: no-repeat;
  }
  //   完成后切换图片
  .backdrop2 {
    // background-image: url("../../assets/img/rectangle2.png");

    background: #8446fc;
    color: #fff;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  //   提示文字
  .switch_font {
    text-align: center;
    width: 88%;
    font-size: 16px;
    height: 48px;
    line-height: 48px;
    margin: 20px auto;
    color: #8d8e99;
    border-bottom: 1px solid #c4c4cc;
  }
  //   盒子通用样式
  .common {
    box-sizing: border-box;
    width: 560px;
    margin: auto;
    .statement {
      display: inline-block;
      width: 100%;
      text-align: center;
      color: red;
    }
    .verify2 {
      display: inline-block;
      text-align: center;
      margin-left: 50px;
      width: 140px;
      height: 40px;
      color: #fff;
      background: #c4c4cc;
      border-radius: 5px 5px 5px 5px;
      opacity: 1;
    }
    .verify1 {
      margin-left: 50px;

      width: 140px;
      height: 40px;
      color: #fff;
      background: #8446fc;
      border-radius: 5px 5px 5px 5px;
      opacity: 1;
    }
  }
  //   基本信息盒子样式
  .basic_box {
    .society_Intro {
      display: inline-block;
      width: 500px;
    }
    .basic_but {
      margin: auto;
      width: 140px;
    }
  }
  //   蓝色按钮
  .but_blue {
    width: 120px;
    height: 46px;
    background: #8446fc;
    color: #fff;
    border-radius: 10px 10px 10px 10px;

    opacity: 1;
  }
  .verify3 {
    position: relative;
    width: 120px;
    height: 46px;
    background: #c4c4cc;

    color: #fff;
    border-radius: 10px 10px 10px 10px;

    opacity: 1;
  }
  //  按钮盒子
  .but_box {
    width: 300px;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;

    .esc_but {
      width: 120px;
      height: 46px;
      background: #fff;
      color: #5e5e66;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      border: 1px solid #5e5e66;
    }
  }
  .security_code {
    width: 240px;
  }
  .logoStyle2 {
    width: 168px;
    height: 160px;
    opacity: 1;

    img {
      width: auto;
      height: 100%;
      border-radius: 10px;
      //   border: 1px solid #5e5e66;
    }
  }
  .logoStyle {
    position: relative;
    width: 168px;
    height: 160px;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    border: 1px solid #5e5e66;
    span:nth-child(1) {
      margin-left: 42px;
      margin-top: 82px;
      display: inline-block;
      width: 78px;
      // height: 1px;
      border-bottom: 4px solid #c4c4cc;
    }
    span:nth-child(2) {
      position: absolute;
      top: 42px;
      left: 82px;
      display: inline-block;
      height: 78px;
      // height: 1px;
      border-left: 4px solid #c4c4cc;
    }
    .fileLogo {
      opacity: 0;

      position: absolute;
      // background: red;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  //   材料上传
  .materials {
    .showImg {
      box-sizing: border-box;
      width: 200px;
      height: 120px;
      opacity: 1;
      img {
        width: auto;
        height: 100%;
        border-radius: 10px;
      }
    }
    .idPicture {
      position: relative;
      box-sizing: border-box;
      width: 200px;
      height: 120px;
      border-radius: 10px;
      opacity: 1;
      border: 1px solid #5e5e66;
      span:nth-child(1) {
        margin: 55px auto;
        display: block;
        width: 52px;
        border-bottom: 4px solid #c4c4cc;
      }
      span:nth-child(2) {
        position: absolute;
        top: 33px;
        left: 97px;
        display: block;
        height: 52px;
        border-left: 4px solid #c4c4cc;
      }
      .uploadingIMG {
        width: 100%;
        height: 100%;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
    .imgFont {
      color: #8d8e99;
      font-size: 14px;
      text-align: center;
      display: inline-block;
      width: 200px;
    }
  }
}
</style>